import React from 'react';
import { Link } from 'gatsby';
import { TiChevronRight } from '@react-icons/all-files/ti/TiChevronRight';
import "../assets/css/breadcrumb.css";

const Breadcrumb = () => {
    const location = (typeof window !== 'undefined') && window.location;
    const pathName = location?.pathname?.split("/")?.filter(Boolean);
    return (
        <>
            {pathName?.map((segment, index) => {
                const url = `/${pathName?.slice(0, index + 1)?.join('/')}`;
                const isActive = url === location?.pathname
                return (
                    <span key={index} className='bread-crumb'>
                        <Link to={url} className='breadcrumb-item'>
                            {index !== 0 && <span className='arrow-next-icon'><TiChevronRight size={"20px"} /></span>}
                            <span className={`${isActive ? "active" : "non-active"}`}>{isActive ? `PRODUCT ID: ${segment}` : segment?.toUpperCase()}</span>
                        </Link>
                    </span>
                );
            })}
        </>);
}

export default Breadcrumb;
